import {
  Environment,
  Network,
  QueryResponseCache,
  RecordSource,
  Store,
} from 'relay-runtime';
import { getAPIEndpoint } from '../configs';

const oneHour = 60 * 60 * 1000;
const cache = new QueryResponseCache({ size: 250, ttl: oneHour });

// Unused last param removed: uploadables
function fetchQuery(operation, variables, cacheConfig) {
  const queryID = operation.text;
  const isQuery = operation.operationKind === 'query';
  const forceFetch = cacheConfig && cacheConfig.force;

  // Try to get data from cache on queries
  const fromCache = cache.get(queryID, variables);
  if (isQuery && fromCache !== null && !forceFetch) {
    return fromCache;
  }

  // otherwise, fetch data from server
  return fetch(`${getAPIEndpoint()}graphql`, {
    method: 'POST',
    headers: {
      // Add authentication and other headers here
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      query: operation.text, // GraphQl text from input
      variables,
    }),
  })
    .then((response) => response.json().then((res) => res))
    .then((json) => {
      if (json) {
        cache.set(queryID, variables, json);
      }
      return json;
    });
}

// Create a network layer from the fetch function
const network = Network.create(fetchQuery);
const store = new Store(new RecordSource());

const environment = new Environment({
  network,
  store,
});

export default environment;
