import debug from 'debug';
import React, { useState } from 'react';
import { QueryRenderer } from 'react-relay';
import { BreakpointProvider, setDefaultBreakpoints } from 'react-socks';
import { createGlobalStyle } from 'styled-components';
import 'url-search-params-polyfill';
import {
  BooleanParam,
  decodeDelimitedArray,
  encodeDelimitedArray,
  StringParam,
  useQueryParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import regionConfig from '../../configs/partnerConfig';
import environment from '../../graphql/environment';
import Routes from '../../routes/Routes';
import { breakpoint, globalLayout } from '../../theme/globalLayout';
import { globalStyles } from '../../theme/globalStyles';
import { disableCacheParam, showcaseHost } from '../../utils/constants';
import {
  removeScrollItem,
  saveScrollItem,
} from '../../utils/scrollPositionManager';
import { ActionsProvider } from '../ActionsContext/ActionsContext';
import { ActiveTagsProvider } from '../ActiveTagsContext/ActiveTagsContext';
import { AllTagsProvider } from '../AllTagsContext/AllTagsContext';
import ErrorPage from '../ErrorPage/ErrorPage';
import Footer from '../Footer/Footer';
import AppQuery from './AppQuery';

const debugLog = debug('adshowcase:App');

const GlobalStyles = createGlobalStyle`${globalStyles}`;
const GlobalLayout = createGlobalStyle`${globalLayout}`;

setDefaultBreakpoints([{ smaller: 0 }, { larger: breakpoint }]);

/** Uses a comma to delimit entries. e.g. ['a', 'b'] => qp?=a,b */
/* part of use-query-params, no need to test these 2 helper methods */
/* istanbul ignore next */
const CommaArrayParam = {
  encode: (array) => encodeDelimitedArray(array, ','),
  decode: (arrayStr) => decodeDelimitedArray(arrayStr, ','),
};

const emptyParamsState = {
  formatCategories: undefined,
  adSpaces: undefined,
  features: undefined,
  devices: undefined,
  verticals: undefined,
  publishers: undefined,
  kpis: undefined,
  clients: undefined,
  search: undefined,
  regionId: undefined,
};

// default query parameters
const initialQueryqueryVars = {
  search: null,
  after: null,
  count: null,
  formatCategories: null,
  features: null,
  adSpaces: null,
  devices: null,
  verticals: null,
  publishers: null,
  kpis: null,
  clients: null,
  active: null,
  draft: null,
  regionNames: null,
  regionId: null,
  deleted: null,
};
// new instance of the App, delete any saved scroll params.
removeScrollItem();

const App = (appProps) => {
  debugLog('App recieved: ', appProps);
  const [nocache] = useQueryParam(disableCacheParam, BooleanParam);
  const [searchTerm, setSearchTerm] = useQueryParam('search', StringParam);
  // if we find region in params, parse it out.
  const [region] = useQueryParam('region', StringParam);
  let queryVars = { ...initialQueryqueryVars };

  const initialParamsState = { ...emptyParamsState }; // shallow copy
  const initialURLState = new URLSearchParams(window.location.search);
  Object.keys(initialParamsState).forEach((item) => {
    const initialValuefromUrl =
      CommaArrayParam.decode(initialURLState.get(item)) || [];
    initialParamsState[item] = initialValuefromUrl;
    queryVars = {
      ...queryVars,
      [item]:
        initialParamsState[item] && initialParamsState[item].length > 0
          ? initialParamsState[item]
          : null,
    };
  });
  const [paramArrays, setParamArrays] = useState(initialParamsState); // feed params from URL for initial state
  const [mobileNavOpen, setmobileNavOpen] = useState(false);

  // determine host for query param
  let {
    location: { host },
  } = window;
  // handle localhost and dev instance via regionId
  host = host && host.indexOf(showcaseHost) > -1 ? host : null;
  // if region is available, provide only region
  host = region ? null : host;
  const appQueryVariables = {
    active: true,
    deleted: false,
    baseURL: host,
    regionId: parseInt(region, 10),
  };

  // eslint-disable-next-line
  const [param, setParam] = useQueryParams({
    formatCategories: withDefault(CommaArrayParam, [], false),
    adSpaces: withDefault(CommaArrayParam, [], false),
    features: withDefault(CommaArrayParam, [], false),
    devices: withDefault(CommaArrayParam, [], false),
    verticals: withDefault(CommaArrayParam, [], false),
    publishers: withDefault(CommaArrayParam, [], false),
    kpis: withDefault(CommaArrayParam, [], false),
    clients: withDefault(CommaArrayParam, [], false),
    search: withDefault(StringParam, '', false),
    region: withDefault(StringParam, '', false),
  });
  // todo: move activeExpandedId to context
  const [activeExpandId, setActiveExpandId] = useState(0); // 0 = none expanded, otherwise use key

  const handleResetFilter = () => {
    // we need an id different from 0 to handle search clearing
    // without closing the menu
    setActiveExpandId(-1);
    const resetParamsState = { ...emptyParamsState }; // shallow copy
    setParamArrays(resetParamsState); // this resets checkboxes only
    setParam(
      {
        formatCategories: undefined,
        adSpaces: undefined,
        features: undefined,
        devices: undefined,
        verticals: undefined,
        publishers: undefined,
        kpis: undefined,
        clients: undefined,
        search: undefined,
      },
      'pushIn'
    ); // resets the URL parameters. this syntax is ugly but needed w/ the use-query-params pkg
    // use pushIn not replace bc we dont want to overwrite any region param
  };

  const handleResetSearch = () => {
    setSearchTerm('');
  };

  const handleResetAll = () => {
    handleResetSearch();
    handleResetFilter();
  };

  const toggleArray = (arr, toggleParam) => {
    const newArr = arr.includes(toggleParam)
      ? arr.filter((word) => word !== toggleParam)
      : [...arr, toggleParam];
    const returnArrOrNull = newArr.length === 0 ? undefined : newArr;
    return returnArrOrNull;
  };

  const handleClick = (paramId, categoryId, keyId) => {
    // console.log(paramId, categoryId);
    handleResetSearch();
    // filter or search is requested, invalidate any stored scroll params
    saveScrollItem(1, false, -1);
    // paramstate is only updated on handleClick
    // redirect from results page does not update the state
    // work-around use initialParamsState
    const newArrState = toggleArray(initialParamsState[categoryId], paramId);
    paramArrays[categoryId] = newArrState || undefined;
    // please excuse this code, the query param pkg cannot handle dynamic keys
    switch (categoryId) {
      case 'formatCategories':
        setParam(
          { formatCategories: newArrState, search: undefined },
          'pushIn'
        );
        break;
      case 'adSpaces':
        setParam({ adSpaces: newArrState, search: undefined }, 'pushIn');
        break;
      case 'features':
        setParam({ features: newArrState, search: undefined }, 'pushIn');
        break;
      case 'devices':
        setParam({ devices: newArrState, search: undefined }, 'pushIn');
        break;
      case 'verticals':
        setParam({ verticals: newArrState, search: undefined }, 'pushIn');
        break;
      case 'publishers':
        setParam({ publishers: newArrState, search: undefined }, 'pushIn');
        break;
      case 'kpis':
        setParam({ kpis: newArrState, search: undefined }, 'pushIn');
        break;
      case 'clients':
        setParam({ clients: newArrState, search: undefined }, 'pushIn');
        break;
      case 'region':
        setParam({ region: paramId }, 'pushIn');
        break;
      case 'search':
        // reset all active tags and search entire space
        setParam(
          {
            search: paramId,
            formatCategories: undefined,
            adSpaces: undefined,
            features: undefined,
            devices: undefined,
            verticals: undefined,
            publishers: undefined,
            kpis: undefined,
            clients: undefined,
          },
          'pushIn'
        );
        break;
      default:
        break;
    }
    setActiveExpandId(keyId);
    if (keyId) {
      setmobileNavOpen(true);
    }
  };

  const searchInputRef = React.createRef();

  const handleSearch = (term) => {
    handleClick(term, 'search', 10000);
  };

  // enable setting all filters as collapsed
  // from elsewhere in the app.
  const closeAllFilters = () => {
    // 0 instead of -1 because it is evaluated
    // elsewhere
    setActiveExpandId(0);
  };
  return (
    <QueryRenderer
      environment={environment}
      variables={appQueryVariables}
      cacheConfig={{ force: nocache || false }}
      query={AppQuery}
      render={({ error, props }) => {
        if (error) {
          return (
            <div className="App" data-testid="app">
              <GlobalLayout />
              <GlobalStyles />
              <ErrorPage />
            </div>
          );
        }
        if (!props) {
          return <div />;
        }

        const { showcaseRegion, regions } = props;
        const [reg] = showcaseRegion || [{ _id: 1, text: 'US & Canada' }];
        const regionId = reg._id;
        const currentRegionName = reg.text;
        const config = regionConfig.hasOwnProperty(regionId)
          ? regionConfig[regionId]
          : regionConfig.default;
        const allTags = {};
        for (const item in reg) {
          if (Array.isArray(reg[item]) && reg[item].length > 0) {
            allTags[item] = reg[item];
          }
        }
        // any method or prop defined in App and needs to be passed down can be
        // included in the actions object
        // todo: use reducer with context: https://hswolff.com/blog/how-to-usecontext-with-usereducer/
        const actions = {
          handleSearch,
          handleClick,
          searchTerm,
          closeAllFilters,
          currentRegionName,
          currentRegionId: regionId,
          nocache: nocache || false,
        };
        // ensure search is included if present in query params
        queryVars = {
          ...queryVars,
          search: searchTerm,
          regionId,
        };
        return (
          <ActionsProvider value={actions}>
            <AllTagsProvider
              value={{
                ...allTags,
                ...props,
                regions,
                currentRegionId: regionId,
                config,
              }}
            >
              <ActiveTagsProvider value={queryVars}>
                <BreakpointProvider>
                  <div className="App" data-testid="app">
                    <GlobalStyles />
                    <GlobalLayout />
                    <div className="main" role="main" data-testid="main">
                      <Routes
                        mobileNavOpen={mobileNavOpen}
                        handleResetFilter={handleResetFilter}
                        handleResetSearch={handleResetSearch}
                        handleResetAll={handleResetAll}
                        activeExpandId={activeExpandId}
                        searchInputRef={searchInputRef}
                      />
                    </div>
                    <Footer />
                  </div>
                </BreakpointProvider>
              </ActiveTagsProvider>
            </AllTagsProvider>
          </ActionsProvider>
        );
      }}
    />
  );
};

export default App;
