// use localstorage to save and retrieve  scroll position and
// pagination request count
import debug from 'debug';
import { scrollParamsLS } from './constants';

const debugLog = debug('adshowcase:scrollPositionManager');

export const saveScrollItem = (requestCount, valid = true, yPos = null) => {
  const scrollparams = {
    scrollPositionY: !yPos ? document.body.scrollTop : yPos,
    requestCount,
    valid,
  };
  localStorage.setItem(scrollParamsLS, JSON.stringify(scrollparams));
};

export const removeScrollItem = () => {
  localStorage.removeItem(scrollParamsLS);
};

export const getStoredScrollItem = () => {
  const ls = localStorage.getItem(scrollParamsLS);
  let item = {
    scrollPositionY: 0,
    requestCount: 1,
    valid: false,
  };
  if (ls) {
    try {
      item = JSON.parse(ls);
    } catch (e) {
      debugLog('getStoredScrollItem error:', e);
    }
  }
  return item;
};
