import graphql from 'babel-plugin-relay/macro';

export default graphql`
  query AppQuery(
    $active: Boolean
    $deleted: Boolean
    $baseURL: String
    $regionId: Int
  ) {
    regions: showcaseRegion(active: $active, deleted: $deleted) {
      regionId
      _id: regionId
      text: regionName
      subdomain: regionAbbrv
      regionSort
      languageId
      regionBaseURL
      adspecsURL
      active
      partner
      deleted
    }
    showcaseRegion(
      regionBaseURL: $baseURL
      regionId: $regionId
      active: $active
      deleted: $deleted
    ) {
      regionId
      _id: regionId
      text: regionName
      subdomain: regionAbbrv
      regionBaseURL
      formatCategories: showcaseFormatCategory(
        deleted: $deleted
        active: $active
      ) {
        formatCategoryId
        _id: formatCategoryName
        text: formatCategoryName
      }
      adSpaces: showcaseAdSpace(deleted: $deleted, active: $active) {
        adSpaceId
        _id: adSpaceName
        text: adSpaceName
        deleted
      }
      devices: showcaseDevice(deleted: $deleted, active: $active) {
        deviceId
        _id: deviceName
        text: deviceName
      }
      features: showcaseFeature(deleted: $deleted, active: $active) {
        featuresId
        _id: featuresName
        text: featuresName
      }
      verticals: showcaseVertical(deleted: $deleted, active: $active) {
        verticalId
        _id: verticalName
        text: verticalName
      }
      publishers: showcasePublisher(deleted: $deleted, active: $active) {
        publisherId
        _id: publisherName
        text: publisherName
      }
      kpis: showcaseKPI(deleted: $deleted, active: $active) {
        kpiId
        _id: kpiName
        text: kpiName
      }
      clients: showcaseClient(deleted: $deleted, active: $active) {
        clientId
        _id: clientName
        text: clientName
      }
    }
  }
`;
