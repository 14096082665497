/**
 * @generated SignedSource<<0e5eb797ba2c6be18299308b85dee1c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "active"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "baseURL"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deleted"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "regionId"
},
v4 = {
  "kind": "Variable",
  "name": "active",
  "variableName": "active"
},
v5 = {
  "kind": "Variable",
  "name": "deleted",
  "variableName": "deleted"
},
v6 = [
  (v4/*: any*/),
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "regionId",
  "storageKey": null
},
v8 = {
  "alias": "_id",
  "args": null,
  "kind": "ScalarField",
  "name": "regionId",
  "storageKey": null
},
v9 = {
  "alias": "text",
  "args": null,
  "kind": "ScalarField",
  "name": "regionName",
  "storageKey": null
},
v10 = {
  "alias": "subdomain",
  "args": null,
  "kind": "ScalarField",
  "name": "regionAbbrv",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "regionBaseURL",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deleted",
  "storageKey": null
},
v13 = [
  {
    "alias": "regions",
    "args": (v6/*: any*/),
    "concreteType": "ShowcaseRegion",
    "kind": "LinkedField",
    "name": "showcaseRegion",
    "plural": true,
    "selections": [
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "regionSort",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "languageId",
        "storageKey": null
      },
      (v11/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "adspecsURL",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "active",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "partner",
        "storageKey": null
      },
      (v12/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      (v4/*: any*/),
      (v5/*: any*/),
      {
        "kind": "Variable",
        "name": "regionBaseURL",
        "variableName": "baseURL"
      },
      {
        "kind": "Variable",
        "name": "regionId",
        "variableName": "regionId"
      }
    ],
    "concreteType": "ShowcaseRegion",
    "kind": "LinkedField",
    "name": "showcaseRegion",
    "plural": true,
    "selections": [
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      {
        "alias": "formatCategories",
        "args": (v6/*: any*/),
        "concreteType": "ShowcaseFormatCategory",
        "kind": "LinkedField",
        "name": "showcaseFormatCategory",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "formatCategoryId",
            "storageKey": null
          },
          {
            "alias": "_id",
            "args": null,
            "kind": "ScalarField",
            "name": "formatCategoryName",
            "storageKey": null
          },
          {
            "alias": "text",
            "args": null,
            "kind": "ScalarField",
            "name": "formatCategoryName",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "adSpaces",
        "args": (v6/*: any*/),
        "concreteType": "ShowcaseAdSpace",
        "kind": "LinkedField",
        "name": "showcaseAdSpace",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "adSpaceId",
            "storageKey": null
          },
          {
            "alias": "_id",
            "args": null,
            "kind": "ScalarField",
            "name": "adSpaceName",
            "storageKey": null
          },
          {
            "alias": "text",
            "args": null,
            "kind": "ScalarField",
            "name": "adSpaceName",
            "storageKey": null
          },
          (v12/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "devices",
        "args": (v6/*: any*/),
        "concreteType": "ShowcaseDevice",
        "kind": "LinkedField",
        "name": "showcaseDevice",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deviceId",
            "storageKey": null
          },
          {
            "alias": "_id",
            "args": null,
            "kind": "ScalarField",
            "name": "deviceName",
            "storageKey": null
          },
          {
            "alias": "text",
            "args": null,
            "kind": "ScalarField",
            "name": "deviceName",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "features",
        "args": (v6/*: any*/),
        "concreteType": "ShowcaseFeature",
        "kind": "LinkedField",
        "name": "showcaseFeature",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "featuresId",
            "storageKey": null
          },
          {
            "alias": "_id",
            "args": null,
            "kind": "ScalarField",
            "name": "featuresName",
            "storageKey": null
          },
          {
            "alias": "text",
            "args": null,
            "kind": "ScalarField",
            "name": "featuresName",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "verticals",
        "args": (v6/*: any*/),
        "concreteType": "ShowcaseVertical",
        "kind": "LinkedField",
        "name": "showcaseVertical",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "verticalId",
            "storageKey": null
          },
          {
            "alias": "_id",
            "args": null,
            "kind": "ScalarField",
            "name": "verticalName",
            "storageKey": null
          },
          {
            "alias": "text",
            "args": null,
            "kind": "ScalarField",
            "name": "verticalName",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "publishers",
        "args": (v6/*: any*/),
        "concreteType": "ShowcasePublisher",
        "kind": "LinkedField",
        "name": "showcasePublisher",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "publisherId",
            "storageKey": null
          },
          {
            "alias": "_id",
            "args": null,
            "kind": "ScalarField",
            "name": "publisherName",
            "storageKey": null
          },
          {
            "alias": "text",
            "args": null,
            "kind": "ScalarField",
            "name": "publisherName",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "kpis",
        "args": (v6/*: any*/),
        "concreteType": "ShowcaseKPI",
        "kind": "LinkedField",
        "name": "showcaseKPI",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "kpiId",
            "storageKey": null
          },
          {
            "alias": "_id",
            "args": null,
            "kind": "ScalarField",
            "name": "kpiName",
            "storageKey": null
          },
          {
            "alias": "text",
            "args": null,
            "kind": "ScalarField",
            "name": "kpiName",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "clients",
        "args": (v6/*: any*/),
        "concreteType": "ShowcaseClient",
        "kind": "LinkedField",
        "name": "showcaseClient",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientId",
            "storageKey": null
          },
          {
            "alias": "_id",
            "args": null,
            "kind": "ScalarField",
            "name": "clientName",
            "storageKey": null
          },
          {
            "alias": "text",
            "args": null,
            "kind": "ScalarField",
            "name": "clientName",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AppQuery",
    "selections": (v13/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "AppQuery",
    "selections": (v13/*: any*/)
  },
  "params": {
    "cacheID": "e62f3625311da468f980e912656e86c9",
    "id": null,
    "metadata": {},
    "name": "AppQuery",
    "operationKind": "query",
    "text": "query AppQuery(\n  $active: Boolean\n  $deleted: Boolean\n  $baseURL: String\n  $regionId: Int\n) {\n  regions: showcaseRegion(active: $active, deleted: $deleted) {\n    regionId\n    _id: regionId\n    text: regionName\n    subdomain: regionAbbrv\n    regionSort\n    languageId\n    regionBaseURL\n    adspecsURL\n    active\n    partner\n    deleted\n  }\n  showcaseRegion(regionBaseURL: $baseURL, regionId: $regionId, active: $active, deleted: $deleted) {\n    regionId\n    _id: regionId\n    text: regionName\n    subdomain: regionAbbrv\n    regionBaseURL\n    formatCategories: showcaseFormatCategory(deleted: $deleted, active: $active) {\n      formatCategoryId\n      _id: formatCategoryName\n      text: formatCategoryName\n    }\n    adSpaces: showcaseAdSpace(deleted: $deleted, active: $active) {\n      adSpaceId\n      _id: adSpaceName\n      text: adSpaceName\n      deleted\n    }\n    devices: showcaseDevice(deleted: $deleted, active: $active) {\n      deviceId\n      _id: deviceName\n      text: deviceName\n    }\n    features: showcaseFeature(deleted: $deleted, active: $active) {\n      featuresId\n      _id: featuresName\n      text: featuresName\n    }\n    verticals: showcaseVertical(deleted: $deleted, active: $active) {\n      verticalId\n      _id: verticalName\n      text: verticalName\n    }\n    publishers: showcasePublisher(deleted: $deleted, active: $active) {\n      publisherId\n      _id: publisherName\n      text: publisherName\n    }\n    kpis: showcaseKPI(deleted: $deleted, active: $active) {\n      kpiId\n      _id: kpiName\n      text: kpiName\n    }\n    clients: showcaseClient(deleted: $deleted, active: $active) {\n      clientId\n      _id: clientName\n      text: clientName\n    }\n  }\n}\n"
  }
};
})();

node.hash = "94b12db41dae00a3c2238cf473baa80d";

module.exports = node;
