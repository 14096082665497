import { createContext, useContext } from 'react';

const AllTagsContext = createContext({});

export function useConfig() {
  const { config } = useContext(AllTagsContext);
  if (config === undefined) {
    throw new Error('useConfig must be used within an AllTagsProvider');
  }
  return config;
}

export const AllTagsProvider = AllTagsContext.Provider;
export const AllTagsConsumer = AllTagsContext.Consumer;
export const useAllTagsContext = () => useContext(AllTagsContext);

export default AllTagsContext;
