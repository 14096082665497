/*
 * This module provide a proxy for tracking application behaviour
 * At the moment, Google Analytic is the only tool used, but other tools can be incorporated in here as well.
 * Note the tracking script is included through /public/index.html
 */

/**
 * Fire page view tracking
 * @param {Object} pageData data for page view tracking
 */
export const trackPageView = (gtagRef, gaTrackingId, pageData = {}) => {
  // console.log('fire pageview');
  const { title, pathname } = pageData;
  const location = window.location.href;

  if (typeof gtagRef === 'function') {
    gtagRef('config', gaTrackingId, {
      page_title: title,
      page_location: location,
      page_path: pathname,
    });
  }
};

/**
 * Fire event tracking
 * @param {String} action
 * @param {Object} eventData
 * @param {String} eventData.category
 * @param {String} eventData.label
 * @param {String} eventData.value
 */
export const trackEvent = (gtagRef, gaTrackingId, action, eventData = {}) => {
  const { category, label, value } = eventData;

  if (typeof gtagRef === 'function') {
    gtagRef('event', action, {
      event_category: category,
      event_label: label,
      value,
    });
  }
};
