import styled from 'styled-components';
import { logoPositiveCDN } from '../../utils/constants';

const CenterDiv = styled.div`
  align-items: center;
  display: flex;
  grid-column: 1 / -1;
  height: 99vh;
  justify-content: center;
`;

const YLogo = styled.img`
  display: block;
`;

// Should make this pretty, maybe even pass in some props.
const ErrorPage = () => (
  <CenterDiv>
    <div>
      <YLogo src={logoPositiveCDN} alt="Yahoo Adtech logo" width="300" />
      An error occured. Please refresh the page.
    </div>
  </CenterDiv>
);

export default ErrorPage;
