// This file is for the decorative stuff. Colors, typography, etc.

// Global theme variables
export const verizonLightGrey = '#f6f6f6';

// Imported in <App />
// See: https://www.styled-components.com/docs/api#createglobalstyle
export const globalStyles = `
html {
    background: white;
    font-family: 'YahooSans', sans-serif;
}

html, body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
h1, h2, h3, h4, h5, h6 {
    magin: 0;
}
button {
    cursor: pointer;
    font-family: 'YahooSans', sans-serif;
    padding: 0;
}
@font-face {
    font-family: 'YahooSans';
    src: url('https://s.yimg.com/cv/apiv2/adspecs/fonts/Yahoo_Sans-Regular.woff2') format('woff2'),
    url('https://s.yimg.com/cv/apiv2/adspecs/fonts/Yahoo_Sans-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    }
    
    @font-face {
    font-family: 'YahooSans';
    src: url('https://s.yimg.com/cv/apiv2/adspecs/fonts/Yahoo_Sans-Italic.woff2') format('woff2'),
    url('https://s.yimg.com/cv/apiv2/adspecs/fonts/Yahoo_Sans-Italic.woff2') format('woff2');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
    }
    
    @font-face {
    font-family: 'YahooSans';
    src: url('https://s.yimg.com/cv/apiv2/adspecs/fonts/Yahoo_Sans-Semibold.woff2') format('woff2'),
    url('https://s.yimg.com/cv/apiv2/adspecs/fonts/Yahoo_Sans-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
    }
    
    @font-face {
    font-family: 'YahooSans';
    src: url('https://s.yimg.com/cv/apiv2/adspecs/fonts/Yahoo_Sans-Bold.woff2') format('woff2'),
    url('https://s.yimg.com/cv/apiv2/adspecs/fonts/Yahoo_Sans-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    }
    
    @font-face {
    font-family: 'YahooSans';
    src: url('https://s.yimg.com/cv/apiv2/adspecs/fonts/Yahoo_Sans-ExtraBold.woff2') format('woff2'),
    url('https://s.yimg.com/cv/apiv2/adspecs/fonts/Yahoo_Sans-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
    }    
`;
