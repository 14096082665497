import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useConfig } from '../components/AllTagsContext/AllTagsContext';
import Analytics from '../components/Analytics/Analytics';

const AsyncHome = lazy(() => import('./Home/Home'));
const AsyncDetail = lazy(() => import('./Detail/Detail'));
const AsyncResults = lazy(() => import('./Results/Results'));
const AsyncNotFound = lazy(() => import('./NotFound/NotFound'));

export default function RoutesComponent(props) {
  // console.log('<Routes /> props'); console.log(props);
  const config = useConfig();
  const location = useLocation();

  return (
    <Suspense fallback={<span />}>
      <Routes>
        <Route path="/index.html" render={() => <Navigate to="/" />} />
        <Route path="/index.htm" render={() => <Navigate to="/" />} />
        <Route path="/index" element={<Navigate to="/" />} />
        <Route path="/home" render={() => <Navigate to="/" />} />
        <Route
          path="/*"
          element={
            <Analytics
              wrappedComponent={AsyncHome}
              title={config.pageTitle}
              trackingCode={config.trackingCode}
              location={location}
              {...props}
            />
          }
        />
        <Route
          path="/detail/*"
          element={
            <Analytics
              wrappedComponent={AsyncDetail}
              title={config.pageTitle}
              trackingCode={config.trackingCode}
              location={location}
              {...props}
            />
          }
        />
        <Route
          path="/results/*"
          element={
            <Analytics
              wrappedComponent={AsyncResults}
              title={config.pageTitle}
              trackingCode={config.trackingCode}
              location={location}
              {...props}
            />
          }
        />
        <Route
          path="/preview/detail/*"
          element={
            <Analytics
              wrappedComponent={AsyncDetail}
              title={config.pageTitle}
              trackingCode={config.trackingCode}
              location={location}
              previewMode
              {...props}
            />
          }
        />
        <Route
          path="/preview/results/*"
          element={
            <Analytics
              wrappedComponent={AsyncResults}
              title={config.pageTitle}
              trackingCode={config.trackingCode}
              location={location}
              previewMode
              {...props}
            />
          }
        />
        <Route
          path="*"
          element={
            <Analytics
              wrappedComponent={AsyncNotFound}
              title={config.pageTitle}
              trackingCode={config.trackingCode}
              location={location}
              {...props}
            />
          }
        />
      </Routes>
    </Suspense>
  );
}
