export const assetTag = {
  hero: 'heroAsset',
  hover: 'hoverAsset',
  demoUrl: 'demoURL',
};
export const defaultSort = {
  order: 'dateCreated',
  dir: 'DESC',
};
export const maxItemsPerPage = {
  desktop: 40,
  mobile: 40,
};
export const maxRelatedAdsCount = {
  desktop: 4,
  mobile: 2,
};
export const cardType = {
  postSales: 'postSales',
  innovation: 'innovation',
};
export const cardImgSize = {
  height: 290,
  width: 250,
};

export const adSpecsLink = 'https://adspecs.yahooinc.com/';
export const contactLink =
  'https://www.adtech.yahooinc.com/advertising/contact-us';
export const actShowcaseLink = 'https://adshowcase.yahooinc.com';
export const actAppLink = 'https://frontpage-ads.yahoo.com/#/';
export const showcaseHost = 'adshowcase.yahooinc.com';
export const globalShowcaseHost = 'global.adshowcase.yahooinc.com';
export const logoPositiveCDN =
  'https://s.yimg.com/cv/apiv2/adshowcase/images/ad-tech_en-US_v_purple_rgb.svg';
export const showcaseRegionUrl = (subdomain) => `${subdomain}.${showcaseHost}`;
export const ryotRegionId = 8;
export const regionMap = [
  {
    subdomain: 'nar',
    adSpecs: 'https://adspecs.yahooinc.com/',
    regionId: 1,
  },
  {
    subdomain: 'emea',
    adSpecs: 'https://emea.adspecs.yahooinc.com/',
    regionId: 2,
  },
  {
    subdomain: 'sea',
    adSpecs: 'https://asia.adspecs.yahooinc.com/',
    regionId: 3,
  },
  {
    subdomain: 'latam',
    adSpecs: 'https://latam.adspecs.yahooinc.com/',
    regionId: 4,
  },
  {
    subdomain: 'hk',
    adSpecs: 'https://hk.adspecs.yahooinc.com/',
    regionId: 5,
  },
  {
    subdomain: 'in',
    adSpecs: 'https://asia.adspecs.yahooinc.com/',
    regionId: 6,
  },
];

// scroll position and card request count LS name
export const scrollParamsLS = 'adshowcase_scroll_params';
// disable cache param
// nocache=1 : disable caching
export const disableCacheParam = 'nocache';
