import styled from 'styled-components';
import { FullBleed, mediaQuerySmaller } from '../../theme/globalLayout';

export const StyledFooter = styled(FullBleed)`
  background: #6001d2;
  color: white;
  font-size: 13px;
  padding: 60px 0 0;
  a {
    color: #fff;
  }
`;

const YFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${mediaQuerySmaller} {
    flex-wrap: wrap;
    #about {
      order: 1;
    }
    #brand {
      order: 3;
    }
    #advertise {
      order: 2;
    }
    #join {
      order: 4;
    }
  }
`;

const YFooterSection = styled.div`
  flex-basis: 16.777%;
  flex-grow: 0;
  font-size: 12px;
  line-height: 1.5;
  padding-right: 15px;
  padding-left: 15px;
  ${mediaQuerySmaller} {
    flex-basis: 50%;
  }

  ul {
    list-style-type: none;
    margin-bottom: 1rem;
    padding: 0;
  }
  li {
    padding: 3px 0;
  }
`;

const YFooterHeading = styled.li`
  font-size: 14px;
  font-weight: 600;
`;

const YFooterTextLink = styled.li`
  display: flex;
  align-items: center;
  a {
    text-decoration: none;
  }
`;

const YButtonWrap = styled.li`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: -3px;
`;

const CCPAImg = styled.img`
  display: block;
  margin-left: 0.25em;
`;

const YButtonLink = styled.a`
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  height: 20px;
  line-height: 1.7;
  min-width: 100px;
  border-radius: 100px;
  outline: none;
  padding: 0px 20px;
  border: 1px solid #fff;
  text-decoration: none;
`;

const Copyright = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #fff;
  padding: 15px 0px 50px;
`;

const Footer = () => {
  let ccpaMsg = {};
  if (window && window.CCPA) {
    ccpaMsg = window.CCPA;
  }
  return (
    <StyledFooter as="footer" className="footer-wrap">
      <YFooter>
        <YFooterSection id="about">
          <ul>
            <YFooterHeading>About us</YFooterHeading>
            <YFooterTextLink>
              <a
                href="https://www.adtech.yahooinc.com/our-brands"
                target="_default"
              >
                Our Brands
              </a>
            </YFooterTextLink>
            <YFooterTextLink>
              <a href="https://www.yahooinc.com/careers/" target="_default">
                Careers
              </a>
            </YFooterTextLink>
            <YFooterTextLink>
              <a href="https://www.yahooinc.com/press/" target="_default">
                Press
              </a>
            </YFooterTextLink>
            <YFooterTextLink>
              <a
                href="https://www.yahooinc.com/accessibility/"
                target="_default"
              >
                Accessibility
              </a>
            </YFooterTextLink>
          </ul>
        </YFooterSection>
        <YFooterSection id="brand">
          <ul>
            <YFooterHeading>Brand trust</YFooterHeading>
            <YFooterTextLink>
              <a
                href="https://legal.yahoo.com/xw/en/yahoo/privacy/topic/b2bprivacypolicy/index.html"
                target="_default"
              >
                Privacy Policy
              </a>
            </YFooterTextLink>
            <YFooterTextLink>
              <a
                href="https://legal.yahoo.com/us/en/yahoo/terms/otos/index.html"
                target="_default"
              >
                Terms of service
              </a>
            </YFooterTextLink>
            <YFooterTextLink>
              <a
                href="https://legal.yahoo.com/us/en/yahoo/privacy/adinfo/index.html"
                target="_default"
              >
                About our ads
              </a>
            </YFooterTextLink>
            <YFooterTextLink>
              <a
                href="https://www.yahooinc.com/business-and-human-rights/"
                target="_default"
              >
                Business &amp; human rights
              </a>
            </YFooterTextLink>
            <YFooterTextLink>
              {
                // NOTE: The braces surrounding this comment, are part of the comment.
                // TODO: ccpaMsg will also have a `ccpaMsg.showDAAPrivacyRightsIcon`
                // When that is true, we need to display an icon :
                // https://s.yimg.com/cv/apiv2/default/20200109/Privacy_Rights_icon.png ( we can optimize to fit our UI/UX )
                // the image will live on the right hand side of the text, and only show IF AND ONLY IF `ccpaMsg.showDAAPrivacyRightsIcon === true`
              }
              <a href={ccpaMsg.url}>{ccpaMsg.label}</a>{' '}
              {ccpaMsg.showDAAPrivacyRightsIcon === true ? (
                <CCPAImg
                  alt=""
                  width="20"
                  height="20"
                  src="https://s.yimg.com/cv/apiv2/default/20200109/Privacy_Rights_icon.png"
                />
              ) : null}
            </YFooterTextLink>
          </ul>
        </YFooterSection>
        <YFooterSection id="advertise">
          <ul>
            <YFooterHeading>Advertise with us</YFooterHeading>
            <li>Find the right advertising solutions for your business.</li>
            <YButtonWrap aria-label="button" role="">
              <YButtonLink href="https://www.adtech.yahooinc.com/advertising/contact-us">
                Get in touch
              </YButtonLink>
            </YButtonWrap>
          </ul>
        </YFooterSection>
        <YFooterSection id="join">
          <ul>
            <YFooterHeading>Join us</YFooterHeading>
            <YFooterTextLink>
              Spend every day connecting people with the things they love.
            </YFooterTextLink>
            <YButtonWrap aria-label="button" role="">
              <YButtonLink href="https://www.yahooinc.com/careers/">
                Search jobs
              </YButtonLink>
            </YButtonWrap>
          </ul>
        </YFooterSection>
      </YFooter>
      <Copyright>
        <span>&copy; 2024 Yahoo. All Rights Reserved</span>
      </Copyright>
    </StyledFooter>
  );
};

export default Footer;
