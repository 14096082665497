import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { trackPageView } from '../../utils/google_analytics';

const { gtag } = window; // eslint-disable-line

/**
 * A higher-order component which automatically track pageviews when application route is changed
 */
export default function Analytics(props) {
  const routeParams = useParams();
  const { title, trackingCode, location } = props;
  useEffect(() => {
    const GA_TRACKING_ID = trackingCode;
    document.title = title || 'Ad Creative Tech Showcase - Yahoo';
    trackPageView(gtag, GA_TRACKING_ID, {
      page_title: title,
      page_path: location.pathname,
    });
  }, [location.pathname]);

  return (
    <props.wrappedComponent
      params={routeParams}
      {...props} // stuff from router & app
    />
  );
}
