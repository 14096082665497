export default {
  default: {
    name: 'Ad Showcase',
    isRyot: false,
    displayTitle: 'Yahoo Creative Ad Showcase',
    pageTitle: 'Yahoo Creative Ad Showcase',
    trackingCode: 'G-YJ3V3EDLGV',
    metaDescription:
      'Welcome to our curated collection of innovative digital advertising and explore how top brands engage their audiences.',
    baseDomain: 'adshowcase.yahooinc.com',
    sizzleVideo:
      'https://s.yimg.com/cv/apiv2/adshowcase/ACT_Sizzle_Updated.mp4',
    cardSortOrder: 'dateCreated',
    cardDir: 'DESC',
    filters: {
      formatCategoryDisplayText: 'Format',
      adspaceDisplayText: 'Ad Space',
      deviceDisplayText: 'Device',
      featuresDisplayText: 'Features',
      verticalDisplayText: 'Vertical',
      publisherDisplayText: 'Publisher',
      kpiDisplayText: 'Capabilities',
      clientDisplayText: 'Client',
    },
    card: {
      labels: {
        heading: {
          field: {
            name: 'advertiser',
            isArray: false,
            property: 'advertiserName',
          },
        },
        body: {
          field: {
            name: 'formatType',
            isArray: false,
            property: 'formatTypeName',
          },
        },
      },
    },
  },
  8: {
    name: 'Yahoo Creative Branded Content Showcase',
    isRyot: true,
    displayTitle: 'Yahoo Creative Branded Content Showcase',
    pageTitle: 'Yahoo Creative Branded Content Showcase',
    trackingCode: 'G-TT8K19YMSR',
    metaDescription:
      'Welcome to the Yahoo Creative branded content showcase. Explore some of our recent partnerships.',
    baseDomain: 'brandedcontent.adshowcase.yahooinc.com',
    sizzleVideo:
      'https://s.yimg.com/cv/apiv2/adshowcase/060922_RYOT_Showcase_V3.1.mp4',
    cardSortOrder: 'dateUpdated',
    cardDir: 'DESC',
    filters: {
      formatCategoryDisplayText: 'Label',
      adspaceDisplayText: 'Ad Space',
      deviceDisplayText: 'Device',
      featuresDisplayText: 'Features',
      verticalDisplayText: 'Vertical',
      publisherDisplayText: 'Publisher',
      kpiDisplayText: 'Format',
      clientDisplayText: 'Client',
    },
    card: {
      labels: {
        heading: {
          field: {
            name: 'advertiser',
            isArray: false,
            property: 'advertiserName',
          },
        },
        subheading: {
          field: {
            name: 'campaignName',
            isArray: false,
            property: false,
          },
        },
        body: {
          field: {
            name: 'formatCategory',
            isArray: true,
            property: 'formatCategoryName',
          },
        },
      },
    },
  },
};
