import styled from 'styled-components';

// Global *layout* variables (positioning & sizes, no colors, fonts, etc)
export const breakpoint = '768';
export const maxWidth = '1272px'; // max width of grid container
export const mediaQuerySmaller = `@media (max-width: ${breakpoint - 1}px)`;
export const mediaQueryLarger = `@media (min-width: ${breakpoint}px)`;
export const mediaQueryMax = `@media (min-width: ${maxWidth})`;
export const sideGutterMobile = '16px';
export const sideGutterDesktop = '20px';
export const gridGapMobile = '32px';
export const gridGapMobileVertical = '32px';
export const gridGapDesktop = '40px';
export const gridGapDesktopVertical = '28px';
export const headerHeight = '90px'; // Mobile nav position depends on this
export const desktopFilterHeadingHeight = '34px';
export const demoVerticalMediaQuery = {
  vBreakpoint: '800px',
  demoMaxWidth: '775px',
};
export const sliderMargin = '30';
export const downloadButtonWidth = 40; // Download video/GIF button
export const maxWidthMinusDownloadButton =
  parseInt(maxWidth, 10) - downloadButtonWidth;

// This file is just for layouts, grids, media queries, etc. No fonts/colors/typography.
// Note: Overflow-x hidden is needed for full bleed effect (color stripes go edge to edge)
export const globalLayout = `
html {
    box-sizing: border-box;
}
*, *:before, *:after {
    box-sizing: inherit;
}
html, body {
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    -webkit-overflow-scrolling: touch;
}
html, body, #root {
    height: 100%;
}
.App {
    display: grid;
    grid-gap: 0 ${gridGapMobile};
    grid-template-areas:
        "header header"
        "main main"
        "footer footer";
    grid-template-columns: 1fr 1fr;
    grid-template-rows:auto 1fr auto;
    height: 100%;
    margin: 0 auto;
    max-width: ${maxWidth};
    min-height: 100%;
    padding: 0 ${sideGutterMobile};
    ${mediaQueryLarger} {
        grid-gap: 0 ${gridGapDesktop};
        grid-template-areas:
            "header header header header"
            "main main main main"
            "footer footer footer footer";
        grid-template-columns: 1fr 1fr 1fr 1fr;
        padding: 0 ${sideGutterDesktop};
    }

    .header-wrap {
        grid-area: header;
        z-index: 9999;
    }
    .main {
        grid-area: main;
        position: relative;
    }
    .footer-wrap {
        grid-area: footer;
    }

}
`;
export const FullBleed = styled.div`
  position: relative;
  &:before {
    background: inherit;
    content: '';
    z-index: -1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -50vw;
    right: -50vw;
  }
`;
